const data = {
  "trainees": [
    {
      "_id": "AT",
      "index": 0,
      "name": "Aiste Tiskeviciute",
      "title": "Junior Developer",
      "headshot": "Aiste.png",
      "cv": "./cv/Aiste_Tiskeviciute.pdf",
      "quote": "How wonderful it is that nobody need wait a single moment before starting to improve the world. - Anne Frank",
      "bio": "A hard-working individual with an aptitude and love for learning. I am easily approachable with an inclination to push myself. This can be seen in me joining a 12-week intensive Software Development program at _Nology, allowing me to bring real-life client web-designs into fruition via code. I'd be sure to bring endless curiosity and boundless enthusiasm to any team.",
      "linkedin": "https://www.linkedin.com/in/aiste-tiskeviciute-760b9b1bb/",
      "github": "https://github.com/AisteTsk",
      "portfolio": "https://aiste-portfolio.web.app/"
    },
    {
      "_id": "BT",
      "index": 1,
      "name": "Ben Tennant",
      "title": "Junior Developer",
      "headshot": "Ben.png",
      "cv": "./cv/Benjamin_Tennant.pdf",
      "quote": "A man is but the product of his thoughts. What he thinks, he becomes. - Gandhi",
      "bio": "While most are still in bed, you'll find me chasing the sunrise, either on the roads or in the gym. Despite being a lone wolf in the early hours of each day, I thrive most with others. Why work alone when you can work as part of a team? One's weakness is another’s strength, so naturally, we work better together. Communication and collaboration, that’s the key.",
      "linkedin": "www.linkedin.com/in/benjamin-tennant-a87700139",
      "github": "https://github.com/b-tennant",
      "portfolio": "http://thebenj.com/"
    },
    {
      "_id": "DE",
      "index": 2,
      "name": "Daniel Evans",
      "title": "Junior Developer",
      "headshot": "Dan_Evans.png",
      "cv": "./cv/Daniel_Evans.pdf",
      "quote": "It always seems impossible until it's done. - Nelson Mandela",
      "bio": "I am a responsible and engaging team player who is open-minded and adaptable. I am calm in complex and difficult situations that require teamwork, practical solutions, critical thinking and analysis. In my spare time, I enjoy gaming. I also enjoy travelling, experiencing new countries and cultures and cooking.",
      "linkedin": "https://www.linkedin.com/in/daniel-evans-35642b1b9/",
      "github": "https://github.com/daniel-jj-evans?tab=repositories",
      "portfolio": "https://portfolio-c141c.web.app/"
    },
    {
      "_id": "DL",
      "index": 3,
      "name": "Danny Lambert",
      "title": "Junior Developer",
      "headshot": "Danny_Lambert.png",
      "cv": "./cv/Danny_Lambert.pdf",
      "quote": "You miss 100% of the shots that you don't take. - Wayne Gretzky",
      "bio": "I am a junior software developer with a mixed background of data analysis and sports coaching. I am looking forward to landing my first role within tech to allow me to put into practice everything I have learnt whilst on the _nology course.",
      "linkedin": "https://www.linkedin.com/in/dannylambert12/",
      "github": "https://github.com/Danny-Lambert",
      "portfolio": "http://dannylambert.co.uk "
    },
    {
      "_id": "EH",
      "index": 4,
      "name": "Ellis Hornabrook",
      "title": "Junior Developer",
      "headshot": "Ellis_Hornabrook.png",
      "cv": "./cv/Ellis_Hornabrook.pdf",
      "quote": "Failure doesn't mean the game is over, it means you should gain more XP and try again.",
      "bio": "A qualified junior developer eager to pair my newly acquired, highly demanded technical ability with the transferrable skills obtained in my previous career. Unapologetically a gaming enthusiast and football fanatic, I thrive in all environments, working independently and in a team.",
      "linkedin": "https://www.linkedin.com/in/ellis-hornabrook/",
      "github": "https://github.com/EllisHornabrook",
      "portfolio": "https://www.ellisthedev.co.uk"
    },
    {
      "_id": "EH2",
      "index": 5,
      "name": "Evan Hynes",
      "title": "Junior Developer",
      "headshot": "Evan_Hynes.png",
      "cv": "./cv/Evan_Hynes.pdf",
      "quote": "You have brains in your head. You have feet in your shoes. You can steer yourself any direction you choose.",
      "bio": "I am a recent 1st class BSc Physics graduate from UCL who is looking for a job in front-end or full-stack software development. I have intermediate coding experience in Java, Python, HTML, CSS, JavaScript, React, Node.js, SCSS & Git. In my time at _nology I have completed various projects such as a JS snake game and a shift reporter final project.",
      "linkedin": "https://www.linkedin.com/in/evan-hynes-b58544184/",
      "github": "https://github.com/mountEvarus",
      "portfolio": "http://mountevarus.io/"
    },
    {
      "_id": "FM",
      "index": 6,
      "name": "Frank McNulty",
      "title": "Junior Developer",
      "headshot": "Frank_McNulty.png",
      "cv": "./cv/Frank_McNulty.pdf",
      "quote": "Life is either a daring adventure or nothing at all.",
      "bio": "I’m somebody who recently took a leap of faith. I decided to embark on a 3 month coding bootcamp which turned out to be one of the best decisions i’ve ever made. I’m hugely passionate and interested in coding. I love the mix of technical and creative challenges it bring and i’m always interested in learning more. I’m also hugely passionate about music and produce and dj electronic music in my own time.",
      "linkedin": "https://www.linkedin.com/in/frank-mcnulty-591785151/",
      "github": "https://github.com/mcnultyfrank",
      "portfolio": "https://mcnultydev.web.app/"
    },
    {
      "_id": "GM",
      "index": 7,
      "name": "Grant McIntyre",
      "title": "Junior Developer",
      "headshot": "Grant_McIntyre.png",
      "cv": "./cv/Grant_McIntyre.pdf",
      "quote": "Ah, but a man's reach should exceed his grasp, or what's a heaven for? - Robert Browning",
      "bio": "I am a 27 year old who has spent the past five years working in the arts, a unique and competitive industry. Now educated and experienced in both culture and technology, I have a broad skill set that would complement a role within technology where communication and relationships lie at the heart.",
      "linkedin": "",
      "github": "https://github.com/grantmcintyre",
      "portfolio": "https://grants-portfolio.web.app/"
    },
    
    {
      "_id": "HH",
      "index": 8,
      "name": "Harry Holden",
      "title": "Junior Developer",
      "headshot": "Harry_Holden.png",
      "cv": "./cv/Harry_Holden.pdf",
      "quote": '"Nothing will work unless you do." -  Maya Angelou',
      "bio": "I am a junior web developer with a former career in aerospace engineering specialising in modelling & simulation. Keen problem solver, enthusiastic team member, with an eagerness to work on anything related to tech, I love to code! Think I could help you out? Let's work together.",
      "linkedin": "https://www.linkedin.com/in/harry-holden-5b11a274",
      "github": "https://github.com/Diplodocus-the-Diplodocus-King",
      "portfolio": "https://www.thediplodocus.co.uk/"
    },
    {
      "_id": "JR",
      "index": 9,
      "name": "Jack Roy",
      "title": "Junior Developer",
      "headshot": "Jack_Roy.png",
      "cv": "./cv/Jack_Roy.pdf",
      "quote": "Have a purpose beyond yourself",
      "bio": "Hi, I’m Jack. I really enjoy coding, and I love designing websites. I am very sociable, and thrive when working with others. My main goal when working is to constantly learn new skills to add to my repertoire.  I hope to join a company that I can learn a lot from, and also contribute a lot to.",
      "linkedin": "https://www.linkedin.com/in/jack-roy-49967a156/",
      "github": "https://github.com/jackroy08/",
      "portfolio": "https://jackroy.dev/"
    },
    {
      "_id": "JW",
      "index": 10,
      "name": "Jake Wood",
      "title": "Junior Developer",
      "headshot": "Jake_Wood.png",
      "cv": "./cv/Jake_Wood.pdf",
      "quote": "He who has a why can bear almost any how. - Viktor Frankl",
      "bio": "The decision to pursue a career in web development ultimately boils down to the fact that it combines two of my biggest values; helping others and being able to explore my creativity. In particular, creating and developing beautifully designed and meaningful applications is something I'm hugely passionate about. While I’m fully aware that I’m at the start of my journey into web development; I am relishing the prospect of building on the strong foundations developed during my time on the _nology course and advancing my skillset further.",
      "linkedin": "https://www.linkedin.com/in/jake-wood-726141114/",
      "github": "https://github.com/jakealistairwood",
      "portfolio": "https://jakealistairwood.github.io/portfolio/"
    },
    {
      "_id": "JL",
      "index": 11,
      "name": "James Loizou",
      "title": "Junior Developer",
      "headshot": "James_Loizou.png",
      "cv": "./cv/James_Loizou.pdf",
      "quote": "A smiley, positive developer who loves to learn!",
      "bio": "Hi! I am a Junior Developer who prides himself on excellent communication and teamwork skills, with a positive attitude! My experience working in a start-up helped me realise that I love to learn and be challenged, while having a palpable impact on the projects I am assigned to. I am hungry for my next challenge after the course and to push my comfort zone out! I am passionate about martial arts, gaming, and travel.",
      "linkedin": "https://www.linkedin.com/in/james-loizou-200330155/",
      "github": "https://github.com/jloizou/Projects",
      "portfolio": "https://portfolio-36197.web.app/"
    },
    {
      "_id": "JC",
      "index": 12,
      "name": "Joe Calleya",
      "title": "Junior Developer",
      "headshot": "Joe_Calleya.png",
      "cv": "./cv/Joe_Calleya.pdf",
      "quote": "I started at the bottom, and I'm working my way down. - Jerry Lee Lewis",
      "bio": "I am a generally relaxed, happy and honest person . I've had a 10 year career in financial database development. This has given me an aptitude for solving problems with code , commercial project experience and an ability to communicate with many types of people. My interest in Tech has allowed my creativity to flow freely and I'm excited to begin my future in Tech.",
      "linkedin": "https://www.linkedin.com/in/joe-calleya-17253037/",
      "github": "https://github.com/joecalleya",
      "portfolio": "https://joecalleya.dev/"
    },
    {
      "_id": "JG",
      "index": 13,
      "name": "John Guilding",
      "title": "Junior Developer",
      "headshot": "John_Guilding.png",
      "cv": "./cv/John_Guilding.pdf",
      "quote": "Whether you think you can or you think you can't, you're right. - Henry Ford",
      "bio": "I am a motivated individual who enjoys solving problems. Most experienced with JavaScript and ReactJS, but eager to expand my tech stack. I am a team player and like working in a collaborative environment. Looking for a challenging but fulfilling role. Excited to transition to the tech industry.",
      "linkedin": "https://www.linkedin.com/in/johnguilding",
      "github": "https://github.com/JohnGuilding",
      "portfolio": "https://johnguilding.dev/"
    },
    {
      "_id": "JM",
      "index": 14,
      "name": "Joseph McGrath",
      "title": "Junior Developer",
      "headshot": "Joseph_McGrath.png",
      "cv": "./cv/Joseph_McGrath.pdf",
      "quote": "Every problem has a solution. You just have to be creative enough to find it. - Travis Kalanick",
      "bio": "A team-oriented developer looking to work with innovative and creative companies. I'm passionate about technology, creativity, and bringing the best out in those that I'm around. I want to work with a team which believes in the work they are doing, supports and encourages one another and is continually innovating and improving what they do.",
      "linkedin": "https://www.linkedin.com/in/joseph-daniel-mcgrath/",
      "github": "https://github.com/jdmcgrath",
      "portfolio": "https://joemcgrath.dev"
    },
    {
      "_id": "KT",
      "index": 15,
      "name": "Kuda Thomas",
      "title": "Junior Developer",
      "headshot": "Kuda_Thomas.png",
      "cv": "./cv/Kudakwashe_Thomas.pdf",
      "quote": "As iron sharpens iron, so one person sharpens another.",
      "bio": "Embarking on a 12-week developer bootcamp has shown me how rewarding a career in software development can be! With a passion for new advances in technology, I have found great enjoyment in learning and developing projects with React and JavaScript. I am still early in my career and want to continue my journey of learning, as I believe working within a company will help me achieve my potential and give me all the tools I need to succeed.",
      "linkedin": "https://www.linkedin.com/in/kudakwashe-thomas-896915170/",
      "github": "https://github.com/Kuda11",
      "portfolio": "https://kudathomas.com/"
    },
    {
      "_id": "ML",
      "index": 16,
      "name": "Manuel Lucas",
      "title": "Junior Developer",
      "headshot": "Manuel_Lucas.png",
      "cv": "./cv/Manuel_Lucas.pdf",
      "quote": "An error does not become a mistake until you refuse to correct it. - JFK",
      "bio": "I transitioned from an experienced nutritionist to a junior dev, just so that I can eat junk food while I code. I am quite determined to get what I want to achieve. In November 2020 I graduated from an intensive course in software development with Nology where I was able to learn to code at a fast pace, work on my portfolio, and experience working 5 weeks on a real client-project using React.",
      "linkedin": "https://www.linkedin.com/in/manuel-krus-galvao-lucas-821b091a2/",
      "github": "https://github.com/manelucas",
      "portfolio": "https://manelucas.github.io/portfolio/"
    },
    {
      "_id": "MR",
      "index": 17,
      "name": "Manuela Redinciuc",
      "title": "Junior Developer",
      "headshot": "Manuela_Redinciuc.png",
      "cv": "./cv/Manuela_Redinciuc.pdf",
      "quote": "When you don't create things, you become defined by your tastes rather than ability. Your tastes only narrow and exclude people, so create. - Jonathan Gillette",
      "bio": "Results-oriented and highly organized professional I’m seeking to expand my career into Software Development. Experienced in Javascript and ReactJS, however I am motivated to progress and learn new skills in my new role. I believe my excellent communication skills, analytical thinking and self-motivation are a good foundation for a career in tech.",
      "linkedin": "https://www.linkedin.com/in/elena-manuela-redinciuc-828a4625/",
      "github": "https://github.com/ManuelaRE",
      "portfolio": "https://manuelare.github.io/portfolio/"
    },
    {
      "_id": "MB",
      "index": 18,
      "name": "Mark Birchall",
      "title": "Junior Developer",
      "headshot": "Mark_Birchall.png",
      "cv": "./cv/Mark_Birchall.pdf",
      "quote": "I'm happiest when I have just opened a new piece of hardware or software and I have to figure out how it is supposed to work and how I can make it work for me.",
      "bio": "I have had a wide variety of jobs, in different sectors. They have all been concerned with communicating effectively to an end user, whether that was as a Bingo Caller on the seafront or Production Editor on a newspaper. I am obsessive about attention to detail. Aside from coding, I love modular synthesis, strategic boardgames and marmite on toast.",
      "linkedin": "https://www.linkedin.com/in/mark-birchall-b519691ba/",
      "github": "https://github.com/markb10101/",
      "portfolio": "https://markbirchall.net/"
    },
    {
      "_id": "MC",
      "index": 19,
      "name": "Matt Church",
      "title": "Junior Developer",
      "headshot": "Matthew_Church.png",
      "cv": "./cv/Matt_Church.pdf",
      "quote": "We of Spurs have set our sights very high, so high in fact, that even failure will have in it an echo of glory.",
      "bio": "I first became interested in coding, working alongside devs while testing a digital transformation project. I taught myself to code in order to better understand defects and communicate better with my colleagues, but I soon realised that I wanted to be solving the problems myself instead!",
      "linkedin": "https://www.linkedin.com/in/matthewjchurch/",
      "github": "https://github.com/matthewjchurch",
      "portfolio": "https://mattchurch.dev/"
    },
    {
      "_id": "NT",
      "index": 20,
      "name": "Nick Truby",
      "title": "Junior Developer",
      "headshot": "Nick_Truby.png",
      "cv": "./cv/Nick_Truby.pdf",
      "quote": "You have to find something that you love enough to jump over hurdles and break through the brick walls - George Lucas",
      "bio": "I have a passion for tech, solving problems and I enjoy being creative. I'm committed, eager to learn, and have a good balance of personality and professionalism. As an approachable and adaptable individual, as well as a hard working and keen team player, I'm looking forward to joining and contributing to progressive and innovative team.",
      "linkedin": "https://www.linkedin.com/in/nicktruby",
      "github": "https://github.com/nicktruby",
      "portfolio": "https://truby.io"
    },
    {
      "_id": "OR",
      "index": 21,
      "name": "Ollie Robins",
      "title": "Junior Developer",
      "headshot": "Ollie_Robins.png",
      "cv": "./cv/Oliver_Robins.pdf",
      "quote": "I find that the harder I work, the more luck I seem to have.",
      "bio": "Having just completed  3 months with Nology on their intensive training course I am really excited to kickstart a new career in tech. I have really enjoyed solving problems and the continuous learning that software development requires. Taking what I have learnt on this course, along with the skills acquired from previous roles I'm excited for a junior developer role.",
      "linkedin": "https://www.linkedin.com/in/ollierobins/",
      "github": "https://github.com/olirob93",
      "portfolio": "https://ollierobins.co.uk"
    },
    {
      "_id": "SR",
      "index": 22,
      "name": "Sophie Roe",
      "title": "Junior Developer",
      "headshot": "Sophie_Roe.png",
      "cv": "./cv/Sophie_Roe.pdf",
      "quote": "Wake up with determination. Go to bed with satisfaction.",
      "bio": "A conscientious _nology graduate with a background in medical science seeking a junior software developer role. I am passionate about learning new skills and helping other people. I am mainly experienced in JavaScript and ReactJS, however I am motivated to progress and learn widely in my next role.",
      "linkedin": "https://www.linkedin.com/in/sophie-roe95/",
      "github": "https://github.com/sophieroe95",
      "portfolio": "https://sophieroe.co.uk/"
    },
    {
      "_id": "SM",
      "index": 23,
      "name": "Steve Maguire",
      "title": "Junior Developer",
      "headshot": "Steve_Maguire.png",
      "cv": "./cv/Steve_Maguire.pdf",
      "quote": "Before beginning a Hunt, it is wise to ask someone what you are looking for before you begin looking for it. — Winnie the Pooh",
      "bio": "Having held senior roles working with a variety of clients, I have a strong background in project management, team leadership and client support. I enjoy collaboration and value open and honest communication within a team to facilitate successful project delivery.",
      "linkedin": "linkedin.com/in/steve-maguire-a5a8926a/",
      "github": "github.com/Zdeevo",
      "portfolio": "https://stevemaguire.dev/"
    },
    {
      "_id": "SS",
      "index": 24,
      "name": "Steve Shackleton",
      "title": "Junior Developer",
      "headshot": "Steve_Shackleton.png",
      "cv": "./cv/Steve_Shackleton.pdf",
      "quote": "You mustn’t be afraid to dream a little bigger",
      "bio": "Hi, I’m Steve, a creative Junior Developer keen to learn new skills and to build on those that I’ve learned already. I’m highly motivated and very interested in the film and screen industry, AI and game technology. In my spare time I produce music as well as act and would be delighted to put my expertise to work for your company.",
      "linkedin": "www.linkedin.com/in/steve-shackleton-43900aa4",
      "github": "https://github.com/Shackleton91",
      "portfolio": "https://shackdev.com/"
    },
    {
      "_id": "VR",
      "index": 25,
      "name": "Vuli Russell",
      "title": "Junior Developer",
      "headshot": "Vuli_Russell.png",
      "cv": "./cv/Vuli_Russell.pdf",
      "quote": "What do sharks do on Monday mornings? They get up and start biting. That's me.",
      "bio": "After completing my masters in chemical engineering, an working as a process engineer for several years I realized that the most enjoyable part of my work was was the times I wrote code to solve problems. I have completed the _nology course with the aim of changing careers and doing it full time!",
      "linkedin": "https://www.linkedin.com/in/vuli-willis-1920791bb/",
      "github": "https://github.com/vuli-russell",
      "portfolio": "https://vulirussell.io/"
    }
  ]
}

export default data;