import React from 'react';
import NavBar from './components/NavBar';
import Main from './containers/Main';
import Footer from './components/Footer';
// import 'normalize.css';

const App = () => {
  return (
    <>
      <NavBar />
      <Main />
      <Footer />
    </>
  )
}

export default App;
